import React, {useEffect, useRef} from 'react'
import { Parallax } from 'react-parallax';
import { Fade } from "react-awesome-reveal";
import {gsap, Power2} from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

//Import images
import AugenLeistung from '../../assets/augenpermanence-leistung-der-augen-amd.jpeg'

function Leistung() {

    const containerRef = useRef(null)
    const revealRef = useRef(null)
    const imgRef = useRef(null)

    useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    }, [])

    useEffect(() => {


        function reveal(){
            
                const tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: containerRef.current,
                        toggleActions: "restart none none reset",
                    },
                });
                tl.to(containerRef.current, {autoAlpha: 1});
                tl.from(containerRef.current, {
                    xPercent: -200,
                    ease: Power2.out,
                    duration: 1,
                });
                tl.from(revealRef.current, {
                    xPercent: 200,
                    duration: 1,
                    delay: -1,
                    ease: Power2.out,
                });
               
        };

        const master = gsap.timeline();
        master.add(reveal());

        return () => {
            ScrollTrigger.getAll().forEach((instance) => {
              instance.kill();
            });
            // This in case a scroll animation is active while the route is updated
            gsap.killTweensOf(containerRef.current);
          };
        

    }, [])

    return (
        <div className="container-heromain" >
            <div className="image-container-heromain" ref={containerRef}>
                <div className="reveal" ref={revealRef}>
                <Parallax bgImage={AugenLeistung} strength={100} className="reveal-image" ref={imgRef}>
                   <div style={{height: 350}}></div>
               </Parallax>
                </div>
            </div>
            <div className="text-container-heromain">
                <div className="text-title">
                    <Fade cascade delay="0.6">
                        <h2>Die Leistung</h2>
                        <h2>Ihrer Augen</h2>
                    </Fade>
                </div>
                <div className="text-content">
                    <Fade cascade delay="0.6">
                        <h3>Durch den normalen Alterungsprozess nimmt die Leistung Ihrer Augen mit der Zeit ab und können Krankheiten wie AMD begünstigen. Blaue Augen, starkes Sonnenlicht, Rauchen, Übergewicht, unausgewogene Ernährung und erhöhte Blutfettwerte können den Prozess beschleunigen.</h3>
                    </Fade>
                </div>
            </div>
        </div>
    )
}

export default Leistung
