import React from 'react'

//Import images
import Composition from '../../assets/makula-lutein-zeaxanthin-kaufen.jpg'

function CTAHome() {
    return (
        <div className="cta-container">
            <div className="cta-image">
                <img src={Composition} alt="AMD Supplement Augenpermanence"></img>
            </div>
            <div className="cta-text-container">
                <h3>Entdecken Sie unsere Pakete</h3>
                <div className="price">
                    <ul>
                        <li>60 Tage Packung Makula</li>
                        <li>Gratis Versand</li>
                        <li>+ ein Willkommensgeschenk</li>
                    </ul>
                    <a href="https://augen-permanence.ch/shop/product/makula/">45.90.- Jetzt bestellen</a>
                </div>
                <div className="price">
                    <ul>
                        <li>120 Tage Packung Makula</li>
                        <li>Gratis Versand</li>
                        <li>+ ein Willkommensgeschenk</li>
                    </ul>
                    <a href="https://augen-permanence.ch/shop/product/makula/">81.80.- Jetzt bestellen</a>
                </div>
            </div>
        </div>
    )
}

export default CTAHome
