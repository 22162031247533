import * as React from "react"


//Import Components
import Layout from '../components/layout'
import Seo from "../components/seo"
import Hero from '../components/Home/Hero'
import Leistung from '../components/Home/Leistung'
import Lutein from '../components/Home/Lutein'
import Makula from '../components/Home/Makula'
import VitalStoff from '../components/Home/VitalStoff'
import Cta from '../components/Home/CTAHome'

//Import CSS
import '../styles/App.scss'

const schema = {
  "@context": "http://schema.org",
  "@graph": 
  [
    {
       "@type": "WebSite",
       "name": "Augen Permanence AG",
       "url": "https://www.augen-permanence.ch/",
       "description": "Wir erstellen einzigartige Weblösungen. Mit unseren geprüften Marketingstrategien wirst Du garantiert im Web gefunden!",
       "potentialAction": {
         "@type": "SearchAction",
         "target": "https://www.augen-permanence.ch/search?q={search_term_string}",
         "query-input": "required name=search_term_string"
       }
    },
    {
      "@type": "Corporation",
      "name": "Augen Permanence AG",
      "alternateName": "AP",
      "url": "https://www.augen-permanence.ch/",
      "logo": "https://www.augen-permanence.ch/assets/augen-permanence-logo-nahrungserganzungsmittel.png"
    },
    {
      "@type": "BreadcrumbList", 
      "itemListElement": [{
        "@type": "ListItem", 
        "position": 1, 
        "name": "Augen Permanence",
        "item": "https://www.augen-permanence.ch/"  
      },{
        "@type": "ListItem", 
        "position": 2, 
        "name": "Über uns",
        "item": "https://www.augen-permanence.ch/uber-uns/"  
      },{
        "@type": "ListItem", 
        "position": 3, 
        "name": "Häufige Fragen",
        "item": "https://www.augen-permanence.ch/faq/"  
      }]
    }
  ]
}


const Home = () => (

  <Layout>
    <Seo 
      title="Unterstützung Ihrer Augengsundheit"
      description="Die Augen Permanence AG bietet mit dem Supplement Makula wichtige Inhaltsstoffe, welche zur Erhaltung der normalen Sehkraft beitragen. Erfahren Sie mehr."
      schemaMarkup={schema} 
    />
    <div className="main-container">
        <Hero />
        <Leistung />
        <Lutein />
        <Makula />
        <VitalStoff />
        <Cta />
    </div>
  </Layout>

)

export default Home


