import React from 'react'
import { Fade } from "react-awesome-reveal";
import {Link} from 'gatsby';
import { StaticImage } from "gatsby-plugin-image"

//Import Images
import SAc from '../../assets/logo-swiss-augenchirurgie.png'
import SAz from '../../assets/swiss-augenzentrum-ag.png'

export default function Hero() {
    return (
        <div className="container-hero">
            
            <div className="hero-text">
            <Fade cascade damping="0.2">  
                    <h2>
                        Sieben Zutaten. <br></br> Eine Formel.
                    </h2>
                
                <h1>
                    Unterstützen Sie auf wissenschaftliche Weise <br></br> 
                    die natürlichen Schutzmechanismen Ihrer Augen
                </h1>
                <a href="https://augen-permanence.ch/shop/product/makula/" className="button-orange">Makula Kaufen</a>
                <div className="shops">
                    <h3>Ebenfalls erhältlich bei:</h3>
                    <div className="shops-logos">
                        <img src={SAc} alt="Swiss Augenchirurgie"></img>
                        <img src={SAz} alt="Swiss Augenzentrum AG"></img>
                    </div>
                </div>
            </Fade>
            </div>
            
            <div className="hero-image">
                <StaticImage
                  className="hero-image-static"
                  src='../../images/makula-box_optimized.png'
                  width={550}
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  placeholder={"none"}
                  alt="Makula AMD - Lutein, Zeaxanthin, Vitamin C, Vitamin E, Vitamin A, Zink, Kupfer"
                />
            </div>
        </div>
    )
}
