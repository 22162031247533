import React, {useEffect, useRef} from 'react'
import { Parallax } from 'react-parallax';
import {gsap, Power2} from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Fade } from "react-awesome-reveal";

//Import images
import RingelBlume from '../../assets/lutein-zeaxanthin-ringelblume-makula.jpg'

function Lutein() {

    const containerRef = useRef(null)
    const revealRef = useRef(null)
    const imgRef = useRef(null)

useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
    }, [])

    useEffect(() => {
        function reveal2(){
            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: containerRef.current,
                    toggleActions: "restart none none reset",
                },
            });
            tl.to(containerRef.current,
                {autoAlpha: 1}
            );
            tl.from(containerRef.current, {
                xPercent: 200,
                duration: 1,
                ease: Power2.out,
            });
            tl.from(revealRef.current, {
                xPercent: -200,
                duration: 1,
                delay: -1,
                ease: Power2.out,
            });
        };

        const master = gsap.timeline();
        master.add(reveal2());

        return () => {
            ScrollTrigger.getAll().forEach((instance) => {
              instance.kill();
            });
            // This in case a scroll animation is active while the route is updated
            gsap.killTweensOf(containerRef.current);
          };
    }, [])

    
    return (
        <div className="container-heromain flex-reverse">
            <div className="image-container-heromain" ref={containerRef}>
                <div className="reveal" ref={revealRef}>
                <Parallax bgImage={RingelBlume} strength={-100} className="reveal-image" ref={imgRef}>
                   <div style={{height: 350}}></div>
               </Parallax>
                </div>
            </div>
            <div className="text-container-heromain">
                <div className="text-title">
                <Fade cascade delay="0.6">
                <h2>Lutein & <br></br> Zeaxanthin</h2>
                </Fade>
                </div>
                <div className="text-content">
                    <Fade cascade delay="0.6">
                        <h3>Im Auge wurden hohe Konzentrationen von Lutein nachgewiesen. Lutein kann für die Makula eine Schutzfunktion vor oxidativen Stress und vor lichtbedingten Schädigungen bewirken.
                            Gewonnen aus der Ringelblume, reichert sich Lutein und Zeaxanthin in Kombination mit bestimmten Vitaminen und Mineralstoffen, wie Zink, bei regelmässiger Zufuhr im Auge an und kann zum Erhalt der normalen Sehkraft beitragen.
                        </h3>
                    </Fade>
                </div>
            </div>
        </div>
    )
}

export default Lutein
