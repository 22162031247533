import React from 'react'
import { Parallax } from 'react-parallax';

//Import Images
import VitalImage from '../../assets/makula-vitamine-nahrstoffe-mineralien-nahrungserganzung.jpg'


//Zusammensetzung Map
const zusammensetzung = [
    {
        id: 1,
        Ingr: 'Zeaxanthin',
        Menge: '1mg'
    },
    {
        id: 2,
        Ingr: 'Lutein',
        Menge: '5mg'
    },
    {
        id: 3,
        Ingr: 'Vitamin C',
        Menge: '125mg'
    },
    {
        id: 4,
        Ingr: 'Vitamin E',
        Menge: '18mg'
    },
    {
        id: 5,
        Ingr: 'Vitamin A',
        Menge: '400mcg'
    },
    {
        id: 6,
        Ingr: 'Kupfer',
        Menge: '0.5mg'
    },
    {
        id: 7,
        Ingr: 'Zink',
        Menge: '7.5mg'
    }
]

// <img src={VitalImage} alt="Die Nahrwerte von Makula. Beitrag zum Erhalt der normalen Sehkraft." className="vital-image" />

function VitalStoff() {
    return (
        <div className="vitalstoff-container">
            <div className="vital-image-container">
                <h2 className="text-overlay">
                    Vitalstoff <br></br> Kombination
                </h2>
               <Parallax bgImage={VitalImage} strength={500} className="vital-image" bgImageAlt="Die Nahrwerte von Makula. Beitrag zum Erhalt der normalen Sehkraft.">
                   <div style={{height: 400}}></div>
               </Parallax>
            </div>
            <div className="vital-text-container">
                <h3>
                Durch die sorgfältige Mischung von Vitaminen, Mineralstoffen, Lutein und Zeaxanthin trägt Makula zur Erhaltung der normalen Sehkraft sowie des Immunsystems bei. 
                Makula ist frei von künstlichen Farbstoffen und die Verpackung wurde der Natur zu Liebe bewusst für eine 60 Tage-Dosis entwickelt.
                </h3>
            </div>
            <div className="vital-zusammensetzung-container">
                {zusammensetzung.map((zusItem) => (
                    <div className="vital-stoff" key={zusItem.id}>
                        <div className="vital-stoff-content">
                            <div className="vital-ingr">
                                <h4>{zusItem.Ingr}</h4>
                            </div>
                            <div className="vital-menge">
                                <h4>{zusItem.Menge}</h4>
                            </div>
                        </div>
                    <hr></hr>
                    </div>
                ))}
            </div>
            <div className="vital-text-container">
                <h3>
                Makula-Kapseln enthalten lediglich, was für Ihren Körper von Nutzen ist. Die von Augenärzten entwickelte Formel ist so dosiert, dass eine Kapsel am Tag reicht.   
                </h3>
            </div>
        </div>
    )
}

export default VitalStoff
